define("discourse/plugins/discourse-6dc-tipper/discourse/components/user-tip-display", ["exports", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/discourse-6dc-tipper/discourse/components/modal/tip-modal", "@ember/template-factory"], function (_exports, _component, _service, _object, _tipModal, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
  <DButton
      @action={{action "toggleModal"}}
      @class={{this.buttonClass}}
      @label="tip_button_title"
      @icon="hand-holding-usd"
  />
  {{/if}}
  */
  {
    "id": "EjxE7TY6",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[8,[39,1],null,[[\"@action\",\"@class\",\"@label\",\"@icon\"],[[28,[37,2],[[30,0],\"toggleModal\"],null],[30,0,[\"buttonClass\"]],\"tip_button_title\",\"hand-holding-usd\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-6dc-tipper/discourse/components/user-tip-display.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    tagName = "";
    shouldDisplay = true;
    location = '';
    buttonClass = 'btn-primary user-card-chat-btn btn-icon-text';
    init() {
      super.init(...arguments);
      this.show(this.getTargetUser());
    }
    show(user) {
      if (this.currentUser) {
        if (user.id === -1 || user.id === this.currentUser.id) this.set("shouldDisplay", false);
      } else {
        this.set("shouldDisplay", false);
      }
    }
    getTargetUser() {
      let user;
      if (!!!this.currentUser) return {
        id: -1,
        username: "system"
      };
      switch (this.location) {
        case "profile-view":
          user = {
            id: this.model.id,
            username: this.model.username
          };
          this.set("buttonClass", "btn-default");
          break;
        case "profile-popup":
          user = {
            id: this.parentView.user.id,
            username: this.parentView.user.username
          };
          break;
        default:
          user = {
            id: this.currentUser.id,
            username: this.currentUser.username
          };
          break;
      }
      return user;
    }
    async toggleModal() {
      this.modal.show(_tipModal.default, {
        model: {
          user: this.getTargetUser()
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleModal", [_object.action]))();
  });
});