define("discourse/plugins/discourse-6dc-tipper/discourse/helpers/increment", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.increment = increment;
  function increment(params) {
    return params[0] + 1;
  }
  var _default = _exports.default = (0, _helper.helper)(increment);
});