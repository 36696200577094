define("discourse/plugins/discourse-6dc-tipper/discourse/routes/preferences-wallet", ["exports", "discourse/routes/restricted-user", "discourse/lib/utilities", "@ember/service"], function (_exports, _restrictedUser, _utilities, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesWalletRoute extends _restrictedUser.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    showFooter = true;
    setupController(controller, user) {
      // if (!user?.can_chat && !this.currentUser.admin) {
      //   return this.router.transitionTo(`discovery.${defaultHomepage()}`);
      // }
      controller.set("model", user);
    }
  }
  _exports.default = PreferencesWalletRoute;
});