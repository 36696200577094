define("discourse/plugins/discourse-6dc-tipper/discourse/templates/siwe-auth-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <form id="siwe-sign" method="POST" action="/auth/siwe/callback" style="display: none;">
    <textarea id="eth_account" name="eth_account"></textarea>
    <textarea id="eth_message" name="eth_message"></textarea>
    <textarea id="eth_signature" name="eth_signature"></textarea>
    <textarea id="eth_avatar" name="eth_avatar"></textarea>
  </form>
  <div class="eth_provider_container">
    <div class="eth_provider_auth_button">
      {{#if (eq this.isAuthLoading 1)}}
        <button class="btn-default btn btn-icon-text" type="button" disabled>
          <div class="spinner small"></div>&nbsp;&nbsp;{{ i18n "eth_providers.buttons.start" }}
        </button>
      {{else if (eq this.isAuthLoading 2)}}
        <button class="btn-default btn btn-icon-text" type="button" disabled>
          <div class="spinner small"></div>&nbsp;&nbsp;Redirecting...
        </button>
      {{else}}
        <button class="btn-primary" type="button" {{ action "initAuth" }}>
          <span class="d-button-label">
            {{ i18n "eth_providers.buttons.start" }}
          </span>
        </button>
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "4+w//V3h",
    "block": "[[[10,\"form\"],[14,1,\"siwe-sign\"],[14,\"method\",\"POST\"],[14,\"action\",\"/auth/siwe/callback\"],[14,5,\"display: none;\"],[12],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"eth_account\"],[14,3,\"eth_account\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"eth_message\"],[14,3,\"eth_message\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"eth_signature\"],[14,3,\"eth_signature\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"eth_avatar\"],[14,3,\"eth_avatar\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"eth_provider_container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"eth_provider_auth_button\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"isAuthLoading\"]],1],null],[[[1,\"      \"],[10,\"button\"],[14,0,\"btn-default btn btn-icon-text\"],[14,\"disabled\",\"\"],[14,4,\"button\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"spinner small\"],[12],[13],[1,\"  \"],[1,[28,[35,2],[\"eth_providers.buttons.start\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"isAuthLoading\"]],2],null],[[[1,\"      \"],[10,\"button\"],[14,0,\"btn-default btn btn-icon-text\"],[14,\"disabled\",\"\"],[14,4,\"button\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"spinner small\"],[12],[13],[1,\"  Redirecting...\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,\"button\"],[24,0,\"btn-primary\"],[24,4,\"button\"],[4,[38,3],[[30,0],\"initAuth\"],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"eth_providers.buttons.start\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"eq\",\"i18n\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-6dc-tipper/discourse/templates/siwe-auth-index.hbs",
    "isStrictMode": false
  });
});