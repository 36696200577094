define("discourse/plugins/discourse-6dc-tipper/discourse/templates/connectors/user-profile-controls/tip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <UserTipDisplay @model={{this.model}} @location="profile-view" />
  */
  {
    "id": "U4UDNNc3",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@location\"],[[30,0,[\"model\"]],\"profile-view\"]],null]],[],false,[\"user-tip-display\"]]",
    "moduleName": "discourse/plugins/discourse-6dc-tipper/discourse/templates/connectors/user-profile-controls/tip.hbs",
    "isStrictMode": false
  });
});