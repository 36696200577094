define("discourse/plugins/discourse-6dc-tipper/discourse/siwe-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("siwe-auth", {
      path: "/discourse-6dc-tipper/auth"
    }, function () {
      this.route("index", {
        path: "/"
      });
    });
  }
});