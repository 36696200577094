define("discourse/plugins/discourse-6dc-tipper/discourse/controllers/preferences-wallet", ["exports", "@ember/controller", "discourse-common/config/environment", "discourse-common/utils/decorators", "@ember/object", "discourse/lib/ajax-error", "@ember/service", "discourse/lib/show-modal", "discourse/plugins/discourse-6dc-tipper/discourse/components/modal/transfer-modal", "discourse/lib/ajax", "discourse/plugins/discourse-6dc-tipper/discourse/lib/web3modal"], function (_exports, _controller, _environment, _decorators, _object, _ajaxError, _service, _showModal, _transferModal, _ajax, _web3modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Web3Utils from "../lib/web3utils";

  class PreferencesWalletController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    constructor() {
      super(...arguments);
      this.set("walletTokenName", "...");
      this.set("walletAddress", "0x0000000000000000");
      this.set("totalBalance", 0);
      this.set("walletBalance", "...");
      this.set("privateWalletAddress", "0x0000000000000000");
      this.set("privateWalletBalance", "...");
      this.set("walletTransactions", []);
      this.fetchWalletData();
      this.fetchWalletTransactions();
    }
    async fetchWalletData() {
      try {
        let {
          status,
          data
        } = await (0, _ajax.ajax)('/discourse-6dc-tipper/fw');
        if (status) {
          let provider = _web3modal.default.create();
          await provider.providerInit({});
          data.forEach(async wallet => {
            const walletInfo = await provider.getBalanceForWallet(this.siteSettings.network_rpc_url, wallet.address, this.siteSettings.erc_20_contract.length ? {
              name: this.siteSettings.currency,
              address: this.siteSettings.erc_20_contract
            } : {
              name: this.siteSettings.currency,
              address: ''
            });
            if (wallet.is_private) {
              this.set("privateWalletAddress", wallet.address);
              this.set("privateWalletBalance", walletInfo.balance);
            } else {
              this.set("walletAddress", wallet.address);
              this.set("walletBalance", walletInfo.balance);
            }
            this.set("totalBalance", parseFloat(this.get("totalBalance")) + parseFloat(walletInfo.balance));
            this.set("walletTokenName", walletInfo.token);
            console.log(wallet.address, "walletInfo:", walletInfo);
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
    splitAndAddEllipsis(str, len) {
      const start = str.slice(0, len);
      const end = str.slice(-len);
      return start + '...' + end;
    }
    async fetchWalletTransactions() {
      try {
        let {
          status,
          data
        } = await (0, _ajax.ajax)('/discourse-6dc-tipper/transactions');
        if (status) {
          const updatedData = data.map(item => {
            return {
              ...item,
              tx_hash: this.splitAndAddEllipsis(item.tx_hash, 23),
              created_at: moment(item.created_at).fromNow() // change the format as needed
            };
          });
          this.set("walletTransactions", updatedData);
        }
      } catch (err) {
        console.error(err);
      }
    }
    get userWallet() {
      return this.walletAddress !== "0x0000000000000000" ? this.walletAddress : false;
    }
    static #_2 = (() => dt7948.n(this.prototype, "userWallet", [(0, _object.computed)('walletAddress')]))();
    get privateUserWallet() {
      return this.privateWalletAddress !== "0x0000000000000000" ? this.privateWalletAddress : false;
    }
    static #_3 = (() => dt7948.n(this.prototype, "privateUserWallet", [(0, _object.computed)('privateWalletAddress')]))();
    async onSendFunds() {
      let data = await (0, _ajax.ajax)('/discourse-6dc-tipper/st').catch(e => {
        return console.error(e);
      });
      console.log(data);
    }
    static #_4 = (() => dt7948.n(this.prototype, "onSendFunds", [_object.action]))();
    onTransferToggle() {
      this.modal.show(_transferModal.default);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onTransferToggle", [_object.action]))();
    async generateWallet() {
      let data = await (0, _ajax.ajax)('/discourse-6dc-tipper/gw').catch(e => {
        return console.error(e);
      });
      console.log(data);
    }
    static #_6 = (() => dt7948.n(this.prototype, "generateWallet", [_object.action]))();
  }
  _exports.default = PreferencesWalletController;
});