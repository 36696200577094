define("discourse/plugins/discourse-6dc-tipper/discourse/initializers/tip", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal", "discourse/plugins/discourse-6dc-tipper/discourse/components/modal/tip-modal", "@ember/service"], function (_exports, _pluginApi, _showModal, _tipModal, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { iconHTML } from "discourse-common/lib/icon-library";
  var _default = _exports.default = {
    name: 'tip',
    initialize(owner) {
      Number.prototype.countDecimals = function () {
        if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
        return this.toString().split(".")[1].length || 0;
      };
      (0, _pluginApi.withPluginApi)('0.1', api => {
        api.attachWidgetAction('post', 'showTipModal', function () {
          owner.lookup("service:modal").show(_tipModal.default, {
            model: {
              user: {
                id: this.attrs.user.id,
                username: this.attrs.user.username
              }
            }
          });
        });
        api.addPostMenuButton('tip', function (attrs) {
          if (api.getCurrentUser()) {
            if (attrs.user.id === api.getCurrentUser().id || attrs.user.id === -1) return {};
          } else {
            return {};
          }
          return {
            data: {
              "post-id": attrs.id,
              "user": attrs.username
            },
            action: 'showTipModal',
            icon: 'hand-holding-usd',
            className: 'tip-user',
            title: 'tip_icon_alt',
            position: 'first' // can be `first`, `last` or `second-last-hidden`
          };
        });
      });
    }
  };
});